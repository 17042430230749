@use 'var' as *;

.justify-start {
  display: flex;
  justify-content: flex-start;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.justify-around {
  display: flex;
  justify-content: space-around;
}

.align-start {
  display: flex;
  align-items: flex-start;
}

.align-end {
  display: flex;
  align-items: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column,
.column {
  display: flex;
  flex-direction: column;
}

.flex-row,
.row {
  display: flex;
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.mb24 {
  margin-bottom: 24px;
  @media (max-width: $sm-size) {
    margin-bottom: 10px;
  }
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.pointer-none {
  pointer-events: none;
}

.uppercase {
  text-transform: uppercase;
}

.bb-1 {
  border-bottom: 1px solid $grey-100;
}

.disabled {
  opacity: 0.38;
  pointer-events: none;
}

.search-highlight {
  background-color: rgba($warning-400, 0.5);
}

.sm-flex {
  display: none !important;

  @media (max-width: $sm-size) {
    display: flex !important;
  }
}

.no-box-shadow {
  box-shadow: none !important;
}
